import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { searchRecipes } from "../../util/contenful";
import "./SearchRecipeContainer.css";

import Clock from './clock.svg';
import Cookies from './cookies.svg';

function SearcRecipeContainer({ match }) {
  const [recipes, setRecipes] = useState([]);
  useEffect(() => {
    searchRecipes(match.params.query).then(entries => {
      const sortedEntries = entries.items.sort(function (a,b) {
        const valueOfA = a.fields.title.toUpperCase();
        const valueOfB = b.fields.title.toUpperCase();
        if (valueOfA < valueOfB) {
          return -1;
        }
        if (valueOfA > valueOfB) {
          return 1;
        }
        return 0;
      })
      setRecipes(sortedEntries);
    });
  }, [match.params.query]);

  return (
    <div className="container content">
      {recipes.length > 0 ? (
        <div>
          <p className="total-count">{recipes.length} opskrifter</p>
          <div className="recipes-container"> 
        {recipes.map(recipe => (
          <Link
            className="recipe"
            to={"/recipe/" + recipe.sys.id}
            key={recipe.sys.id}
          >
            <div className="recipe-container">
              <img className="recipe-image" src={recipe.fields.image ? recipe.fields.image.fields.file.url+'?fm=jpg&fl=progressive' : "http://via.placeholder.com/400x300?text=Billede%20mangler"} alt={recipe.fields.title}/>
              <div className="recipe-info-container">
                <p className="recipe-header">{recipe.fields.title}</p>
                <p className="recipe-subheader">{recipe.fields.subtitle ? recipe.fields.subtitle : ''}</p>
                <div className="recipe-logos">
                  <div className="recipe-logo-container">
                    <img src={Clock} className="recipe-logo"/>
                    <p className="recipe-time-text">{recipe.fields.time ? recipe.fields.time : 'ukendt'}</p>
                  </div>
                  <div className="recipe-logo-container">
                    <img src={Cookies} className="recipe-logo"/>
                    <p className="recipe-amount-text">{recipe.fields.amount}</p>
                  </div>                  
                </div>
              </div>
            </div>
            
          </Link>
        ))}
        </div>
        </div>
      ) : (
        <p>Kunne ikke finde nogle opskrifter...</p>
      )}
    </div>
  );
}

export default SearcRecipeContainer;
