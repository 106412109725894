import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from './logo.svg';
import "./NavMenu.css";

import SearchBar from '../SearchBar/SearchBar';

function NavMenu() {
  let { pathname } = useLocation();
  return (
    <header>
      <Link to="/"><img src={Logo} alt="logo" className="logo"></img></Link>
      <SearchBar class="search-bar"/>
      <nav className="scroll">        
        <Link to="/" className={ pathname === '/' ? 'active' : ''} >Alle</Link>
        <Link to="/forret" className={ pathname === '/forret' ? 'active' : ''}>Forretter</Link>
        <Link to="/hovedret" className={ pathname === '/hovedret' ? 'active' : ''}>Hovedretter</Link>
        <Link to="/dessert" className={ pathname === '/dessert' ? 'active' : ''}>Desserter</Link>
        <Link to="/vegetar" className={ pathname === '/vegetar' ? 'active' : ''}>Vegetar</Link>
      </nav>
    </header>
  );
}

export default NavMenu;
