import React, { useState } from "react";
import { useHistory } from "react-router-dom";

function Recipe(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleKey = event => {
    if (event.key === "Enter") {
      search();
    }
  };

  function search() {
    history.push({
      pathname: `/search/${searchTerm}`
    });
    setSearchTerm("");
  }

  return (
    <div className={props.class}>
      <input type="text" placeholder="Søg..." onChange={handleChange} value={searchTerm} onKeyDown={handleKey} />
    </div>
  );
}

export default Recipe;
