const contentful = require("contentful");

const client = contentful.createClient({
  space: "wdg89rpqhpev",
  accessToken: "4NdEMIEctHC8ORP-VbjqD4P51Wn9AV7YCbnllZTQszE",
  environment: 'master'
});

function baseQuery(category) {
  var query = {'content_type': 'recipe'};
  if (category) {
    if (category === 'vegetar') {
      query['fields.isVegetarian'] = true;
    } else {
      const upperCased = category.replace(/^\w/, c => c.toUpperCase());
      query['fields.type'] = upperCased;
    }
  }
  return query;
}

function getRecipes(category) {
    var query = baseQuery(category);
    return client.getEntries(query);
}

function searchRecipes(searchTerm) {
  console.log(searchTerm);
  var query = baseQuery();
  query['fields.title[match]'] = searchTerm;
  return client.getEntries(query); 
}

function getRecipe(id) {
  var query = {'content_type': 'recipe'};
  if (id) {
    query['sys.id'] = id;
  }
  return client.getEntries(query);
}

export { getRecipes };
export { getRecipe };
export { searchRecipes };