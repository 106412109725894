import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import RecipeContainer from './components/RecipeContainer/RecipeContainer'
import SearchRecipeContainer from './components/SearchRecipeContainer/SearchRecipeContainer'
import NavMenu from './components/NavMenu/NavMenu'
import Recipe from './components/Recipe/Recipe'

import "./App.css";

function App() {
  return (
    <Router>
      <NavMenu />
      <Switch>
        <Route exact path="/:category?" component={RecipeContainer} />
        <Route path="/recipe/:id" component={Recipe} />
        <Route path="/search/:query" component={SearchRecipeContainer} />

      </Switch>
    </Router>
  );
}

export default App;
